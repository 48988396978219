import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import Sidenav from "examples/Sidenav";

// Argon Dashboard 2 PRO MUI themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

import { applicationMappedRoutes, sidebarMappedRoutes, getRoutes } from "parsers/routeParser";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "../src/assets/images/icons/sidenav/logo.png";
import brandDark from "assets/images/logo-ct-dark.png";

// Icon Fonts
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";

export default function App() {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, darkSidenav, darkMode } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const userRole = useSelector((state) => state.auth?.user?.role);
  const { pathname } = useLocation();
  const roleBasedSideBarRoutes = sidebarMappedRoutes(userRole);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const queryParams = new URLSearchParams(location.search);
  const paymentSuccess = queryParams.get("redirect_status");
  if (paymentSuccess == "succeeded") {
    const updatedUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, updatedUrl);
    toast.success("Payment Method added successfully");
  }
  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => { }, [userRole]);

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: "27vw" }}
      />
      {roleBasedSideBarRoutes?.length > 0 && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Blikol"
            routes={roleBasedSideBarRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}

      <Routes>
        {getRoutes(applicationMappedRoutes(userRole))}
        {getRoutes(roleBasedSideBarRoutes)}
      </Routes>
    </ThemeProvider>
  );
}
