import React from 'react'
import DashboardLayout from 'gms-layouts/components/DashboardLayout'
import DashboardNavbar from 'gms-layouts/components/DashboardNavbar'
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import Grid from "@mui/material/Grid";
import ArgonBox from 'components/ArgonBox';
function index() {
    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Dashboard"} />
            <ArgonBox py={3}>
                <Grid container spacing={3} mb={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <DetailedStatisticsCard
                            title="today's money"
                            count="$53,000"
                            icon={{ color: "info", component: <i className="ni ni-money-coins" /> }}
                            percentage={{ color: "success", count: "+55%", text: "since yesterday" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <DetailedStatisticsCard
                            title="today's users"
                            count="2,300"
                            icon={{ color: "error", component: <i className="ni ni-world" /> }}
                            percentage={{ color: "success", count: "+3%", text: "since last week" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <DetailedStatisticsCard
                            title="new clients"
                            count="+3,462"
                            icon={{ color: "success", component: <i className="ni ni-paper-diploma" /> }}
                            percentage={{ color: "error", count: "-2%", text: "since last quarter" }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <DetailedStatisticsCard
                            title="sales"
                            count="$103,430"
                            icon={{ color: "warning", component: <i className="ni ni-cart" /> }}
                            percentage={{ color: "success", count: "+5%", text: "than last month" }}
                        />
                    </Grid>
                </Grid>
            </ArgonBox>
        </DashboardLayout>

    )
}

export default index