import { useDispatch } from "react-redux";
import { setUser, setToken, setLogout } from "slices/authSlice";
import useBaseService from "./useBaseService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useAuthService = () => {
  const dispatch = useDispatch();
  const axiosRequest = useBaseService().axiosRequest();
  const dispatchUserCredentials = async (response) => {
    dispatch(setUser(response.data));
    dispatch(setToken(response.headers["authorization"]));
  };

  const login = async (email, password, rememberMe) => {
    try {
      const response = await axiosRequest.post(
        "/users/login",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "app-platform": "react",
            "app-version": "1",
          },
        }
      );
      dispatchUserCredentials(response);
      toast.success("Login Successfully!");
      return true;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const logout = async () => {
    try {
      const response = await axiosRequest.delete("users/logout");
      dispatch(setLogout());
      toast.success("Logout Successfully!");
      return true;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const forgotPassword = async (email) => {
    try {
      const response = await axiosRequest.post("users/forgot_password", {
        email: email,
      });
      toast.success("Reset password instructions sent successfully.");
      return true;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const resetPassword = async (token, password, confirmPassword) => {
    try {
      const response = await axiosRequest.put("users/reset_password", {
        reset_password_token: token,
        password_confirmation: confirmPassword,
        password: password,
      });
      toast.success("Password reset successfully.");
      dispatchUserCredentials(response);
      return true;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };
  return {
    login,
    logout,
    forgotPassword,
    resetPassword,
  };
};

export default useAuthService;
