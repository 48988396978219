import { NavLink } from "react-router-dom";

// @mui material components
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";

// Render all the nested collapse items from the routes.js
const renderNestedCollapse = (collapse, itemName) => {
  const template = collapse.map(({ name, route, key, href }) =>
    href ? (
      <Link key={key} href={href} target="_blank" rel="noreferrer">
        <SidenavItem name={name} nested />
      </Link>
    ) : (
      <NavLink to={route} key={key}>
        <SidenavItem name={name} active={route === itemName} nested />
      </NavLink>
    )
  );

  return template;
};
// Render the all the collpases from the routes.js
const renderCollapse = (collapses, itemName) =>
  collapses.map(({ name, collapse, route, href, key }) => {
    let returnValue;

    if (collapse) {
      returnValue = (
        <SidenavItem
          key={key}
          name={name}
          active={key === itemName}
          open={openNestedCollapse === name}
          onClick={() =>
            openNestedCollapse === name ? setOpenNestedCollapse(false) : setOpenNestedCollapse(name)
          }
        >
          {renderNestedCollapse(collapse, itemName)}
        </SidenavItem>
      );
    } else {
      returnValue = href ? (
        <Link href={href} key={key} target="_blank" rel="noreferrer">
          <SidenavItem name={name} active={key === itemName} />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavItem name={name} active={key === itemName} />
        </NavLink>
      );
    }
    return <SidenavList key={key}>{returnValue}</SidenavList>;
  });

// Render all the routes from the routes.js (All the visible items on the Sidenav)
export const SideNavRoutesConfigurations = (routes, itemName) => {
  return routes.map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
    let returnValue;

    if (type === "collapse") {
      if (href) {
        returnValue = (
          <Link href={href} key={key} target="_blank" rel="noreferrer">
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        );
      } else if (noCollapse && route) {
        returnValue = (
          <NavLink to={route} key={key}>
            <SidenavCollapse
              name={name}
              icon={icon}
              noCollapse={noCollapse}
              active={key.split("-")[1] === itemName}
            >
              {collapse ? renderCollapse(collapse, itemName) : null}
            </SidenavCollapse>
          </NavLink>
        );
      } else {
        returnValue = (
          <SidenavCollapse
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            open={openCollapse === key}
            onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
          >
            {collapse ? renderCollapse(collapse, itemName) : null}
          </SidenavCollapse>
        );
      }
    } else if (type === "title") {
      returnValue = (
        <ArgonTypography
          key={key}
          color={darkSidenav ? "white" : "dark"}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </ArgonTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider key={key} light={darkSidenav} />;
    }

    return returnValue;
  });
};
