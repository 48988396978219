// @mui material components
import Card from "@mui/material/Card";
import ArgonImage from "components/ArgonImage";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import BasicLayout from "gms-layouts/components/BasicLayout";
import userLogo from "assets/images/login-icon.png";
import { useState } from "react";
import useAuthService from "service-hooks/useAuthService";

function Cover() {
  const [email, setEmail] = useState("");
  const authHookService = useAuthService();
  const navigate = useNavigate();

  const handleForgotPassword = async () => {
    const userForgotPassword = await authHookService.forgotPassword(email);
    if (userForgotPassword) {
      navigate(`/user/forgot-password-success?email=${email}`);
    }
  };

  return (
    <BasicLayout>
      <ArgonBox
        pt={3}
        px={3}
        mb={1}
        sx={{ width: "446px", display: "flex", justifyContent: "center" }}
      >
        <ArgonImage source="/brainx_logo.png " />
      </ArgonBox>
      <Card
        sx={{
          width: "446px",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingTop: "10px",
          paddingBottom: "60px",
          height: "544px"
        }}
      >
        <ArgonBox
          mt={4}
          px={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <ArgonBox component="img" src={userLogo} alt="user" width="15%" mb={2} mt={5} />
          <ArgonBox>
            <ArgonTypography
              textAlign="center"
              variant="h3"
              color="dark"
              sx={{
                width: "auto",
                height: "auto",
                fontSize: "20px",
                letterSpacing: "-0.8px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "137%",
              }}
            >
              Need to reset your password?
            </ArgonTypography>
            <ArgonTypography
              textAlign="center"
              variant="h3"
              mb={2}
              sx={{
                width: "auto",
                height: "auto",
                fontFamily: "Open Sans",
                fontSize: "14px",
                letterSpacing: "-0.8px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "22px",
                color: "#373D3F",
              }}
            >
              Please enter your registered email
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>

        <ArgonBox p={3}>
          <ArgonBox component="form" role="form">
            <ArgonBox mb={3}>
              <ArgonTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="dark"
                sx={{ ml: 0.5, mb: 1 }}
              >
                Email
              </ArgonTypography>
              <ArgonInput
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                size="large"
              />
            </ArgonBox>
            <ArgonBox mt={3} mb={1} textAlign="center">
              <ArgonButton color="info" fullWidth onClick={handleForgotPassword}>
                Continue
              </ArgonButton>
              <ArgonBox px={1} mt={3} textAlign="center">
                <ArgonTypography
                  component={Link}
                  variant="button"
                  color="info"
                  onClick={() => {
                    navigate("/user/login");
                  }}
                  sx={{
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  Back to Login
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
