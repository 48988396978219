import Login from "gms-layouts/authentication/sign-in/cover";
import ForgotPassword from "gms-layouts/authentication/forgot-password/cover";
import ForgotPasswordSuccess from "gms-layouts/authentication/forgot-password/successCover";
import ResetPassword from "gms-layouts/authentication/reset-password/cover";
import AdminDashboard from "gms-layouts/dashboard";

// icons
import DashboardIcon from "@mui/icons-material/HomeOutlined";
export const routes = {
  authentication: [
    {
      key: "login",
      route: "/user/login",
      component: <Login />,
    },
    {
      key: "forgot-password",
      route: "/user/forgot-password",
      component: <ForgotPassword />,
    },
    {
      key: "forgot-password-success",
      route: "/user/forgot-password-success",
      component: <ForgotPasswordSuccess />,
    },
    {
      key: "reset-password",
      route: "/user/reset-password",
      component: <ResetPassword />,
    }
  ],
  super_admin: [

  ]
};

export const sidebarRoutes = {
  super_admin: [
    {
      type: "collapse",
      noCollapse: true,
      name: "Dashboard",
      key: "super-admin-dashboard",
      layout: "dashboard",
      icon: <DashboardIcon fontSize="medium" />,
      route: "/super_admin/dashboard",
      component: <AdminDashboard />,
    }
  ]
};
