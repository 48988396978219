import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from "@mui/material/MenuItem";


// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import Breadcrumbs from "examples/Breadcrumbs";
import DefaultItem from "examples/Items/DefaultItem";
import useAuthService from "service-hooks/useAuthService";
import userIcon from "../../../assets/images/icons/blikol/user.svg";
import notificationIcon from "../../../assets/images/icons/blikol/notification.svg";
import dropdownIcon from "../../../assets/images/icons/blikol/dropdown.svg";
import ConfirmationModal from "examples/ConfirmationModal"


// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import logoutIcon from "assets/images/icons/blikol/logout.svg";

function DashboardNavbar({ absolute, light, isMini, customRoutePath }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const authHookService = useAuthService();
  const user = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  function formatString(inputString) {
    return inputString.replace(/_/g, ' ');
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    // window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    // return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogout = async () => {
    await authHookService.logout();
  };

  const MenuItemStyle = {
    paddingLeft: "0px",
    color: '#11142D',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    '&:hover': {
      backgroundColor: 'transparent !important', // Set the background color to transparent on hover
    },


  }

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 0 }}
    >
      {user?.role == "clinic" ? (
        <ArgonBox sx={{ padding: "30px 30px 0px 30px" }}>
          <ArgonBox
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "15px"
            }}
          >
            <ArgonBox>
              <AccountCircleIcon sx={{ width: "36px", height: "36px", color: "#D9D9D999" }} />
            </ArgonBox>
            <ArgonBox
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <ArgonTypography
                sx={{
                  color: '#11142D',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  textTransform: "capitalise"
                }}
              >
                {user?.name}
              </ArgonTypography>
              <ArgonTypography
                sx={{
                  color: '#808191',
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  textTransform: "capitalize"

                }}
              >
                {user?.role}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ArgonTypography
              sx={{
                color: '#11142D',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: 'normal',
              }}

              my={2}
            >
              Credits Available
            </ArgonTypography>
            <ArgonBox
              sx={{
                display: 'flex',
                width: '78px',
                height: '24px',
                padding: '10px 20px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                borderRadius: '8px',
                background: 'var(--Primary-Color, #207FB9)',

              }}
              mb={2}
            >
              <ArgonTypography
                sx={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '22px',
                }}
              >
                {user?.credits}
              </ArgonTypography>
            </ArgonBox>
            <ArgonTypography
              sx={{
                color: '#11142D',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',

              }}
              mb={1}
            >
              Support Email
            </ArgonTypography>
            <ArgonTypography
              sx={{
                color: '#808191',
                fontSize: '13px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: 'normal',

              }}
              mb={1}
            >
              info@blikol.com
            </ArgonTypography>
            <MenuItem
              sx={MenuItemStyle}
              onClick={() => navigate("/clinic/plans")}
            >
              Purchase Credits
            </MenuItem>
            <MenuItem
              sx={MenuItemStyle}
              onClick={() => {
                navigate("/clinic/payment-methods");
              }}
            >
              Payment Method
            </MenuItem>
            <MenuItem
              sx={MenuItemStyle}
              onClick={() => {
                navigate("/clinic/change-password");
              }}
            >
              Change Password
            </MenuItem>
            <MenuItem
              sx={MenuItemStyle}
              onClick={() => {
                navigate("/clinic/profile");
              }}
            >
              Edit Profile
            </MenuItem>
            <MenuItem
              sx={MenuItemStyle}
              onClick={() => {
                navigate("/clinic/statistics");
              }}
            >
              Statistics
            </MenuItem>
          </ArgonBox>
        </ArgonBox>
      )
        :
        (
          <ArgonBox sx={{ padding: "30px 30px 0px 30px" }}>
            <ArgonBox
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "15px"
              }}
            >
              <ArgonBox>
                <AccountCircleIcon sx={{ width: "36px", height: "36px", color: "#D9D9D999" }} />
              </ArgonBox>
              <ArgonBox
                sx={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <ArgonTypography
                  sx={{
                    color: '#11142D',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: "capitalize"
                  }}
                >
                  {user?.name}
                </ArgonTypography>
                <ArgonTypography
                  sx={{
                    color: '#808191',
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    textTransform: "capitalize"

                  }}
                >
                  {formatString(user?.role)}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
            <ArgonBox
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <MenuItem
                sx={MenuItemStyle}
                onClick={() => {
                  navigate(`/${user?.role}/settings`);
                }}
              >
                Settings
              </MenuItem>
            </ArgonBox>
          </ArgonBox>
        )
      }
      <ArgonBox sx={{ padding: "0px 30px 20px 30px" }}>
        <MenuItem
          sx={{
            display: "flex",
            paddingLeft: "0px",
            alignItems: "center",
            color: "red",
            '&:hover': {
              backgroundColor: 'transparent !important',
              color: "red !important"
            },
          }}
          onClick={() => setShowConfirmationModal(true)}
        >
          Logout
        </MenuItem>
      </ArgonBox>
    </Menu >

  );

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme, { navbarType })}>
          <ArgonBox
            color={light && transparentNavbar ? "white" : "dark"}
            mb={{ xs: 1, md: 0 }}
            sx={(theme) => navbarRow(theme, { isMini })}
          >
            {/* <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={transparentNavbar ? light : false}
          /> */}
            <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
            <ArgonTypography
              variant="text"
              fontWeight="medium"
              color={light && transparentNavbar ? "white" : "dark"}
              sx={{ fontSize: "14px", marginLeft: "10px" }}
            >
              {customRoutePath}
            </ArgonTypography>
          </ArgonBox>
          {isMini ? null : (
            <ArgonBox sx={(theme) => navbarRow(theme, { isMini })}>
              <ArgonBox
                color={light ? "white" : "inherit"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <IconButton
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                  sx={{ fontSize: "4rem", color: "secondary" }}
                >
                  <ArgonBox component="img" src={userIcon} alt="user" width="2.5rem" mr={1} />
                  <ArgonTypography
                    variant="button"
                    fontWeight="medium"
                    color={light && transparentNavbar ? "white" : "dark"}
                  >
                    {user?.name || user?.email}
                  </ArgonTypography>
                  <ArgonBox component="img" src={dropdownIcon} alt="dropdown" ml={2} width="0.6rem" />
                </IconButton>{" "}
                {renderMenu()}
              </ArgonBox>
            </ArgonBox>
          )}
        </Toolbar>
      </AppBar>
      <ConfirmationModal
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleLogout}
        cancelButtonText={"Cancel"}
        confirmButtonText={"Logout"}
        confirmButtonColor={"red"}
        modalHeadingText={"Logout"}
        confirmationText={"Are you sure you want to logout?"}
      />
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: true,
  isMini: false,
  customRoutePath: "",
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  customRoutePath: PropTypes.string,
};

export default DashboardNavbar;
