import { useState } from "react";
import loginLogo from "../../../../../src/assets/images/login-icon.png";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonImage from "components/ArgonImage";
// Authentication layout components
import BasicLayout from "gms-layouts/components/BasicLayout";
// import { useDispatch } from "react-redux";
import useAuthService from "service-hooks/useAuthService";
// Images

function Cover() {
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const navigate = useNavigate();
  const authHookService = useAuthService();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleLogin = async () => {
    const userSignedIn = await authHookService.login(email, password, rememberMe);
    console.log("i was clicked")
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <BasicLayout>
      <ArgonBox sx={{ width: "446px", display: "flex", justifyContent: "center" }}>
        <ArgonImage source="/brainx_logo.png" mb="20px" />
      </ArgonBox>
      <Card sx={{ width: "446px", height: "544px", paddingBottom: "20px" }}>
        <ArgonBox mt={4} pt={3} px={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <ArgonBox component="img" src={loginLogo} alt="login" width="10%" mb={3} />
          <ArgonTypography
            pb={1}
            textAlign="center"
            variant="h3"
            color="dark"
            fontWeight="bold"
            sx={{ width: "60px", height: "27px", fontSize: "20px", letterSpacing: "-0.7px" }}
          >
            Login
          </ArgonTypography>
        </ArgonBox>

        <ArgonBox p={5}>
          <ArgonBox component="form" role="form">
            <ArgonBox mb={3}>
              <ArgonTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="dark"
                sx={{ ml: 0.5, mb: 1 }}
              >
                Email
              </ArgonTypography>
              <ArgonInput
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                size="large"
              />
            </ArgonBox>
            <ArgonBox>
              <ArgonTypography
                display="block"
                variant="caption"
                fontWeight="regular"
                color="dark"
                sx={{ ml: 0.5, mb: 1 }}
              >
                Password
              </ArgonTypography>
              <ArgonInput
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
                size="large"
              />
            </ArgonBox>
            <ArgonBox px={1} textAlign="right">
              <ArgonTypography
                component={Link}
                to="/user/forgot-password"
                variant="button"
                fontWeight="bold"
                color="info"
                textAlign="right"
                sx={{
                  fontSize: "14px",
                  lineHeight: "22px",
                  fontStyle: "normal",
                  padding: "10px 0px 9px 0px",
                  alignItem: "center",
                }}
              >
                Forgot Password?
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mt={4}>
              <ArgonButton
                size="large"
                color="info"
                fullWidth
                onClick={handleLogin}
                display="flex"
                width="350px"
                height="41px"
                sx={{
                  padding: "10px 0px 9px 0px",
                }}
              >
                Login
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
