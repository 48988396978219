//Confirmation Modal
import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ArgonBadge from "components/ArgonBadge";
import ArgonBox from "components/ArgonBox";

const ResetConfirmModal = ({
  open,
  onClose,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  confirmButtonColor,
  modalHeadingText,
  confirmationText,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <ArgonBox sx={{ padding: "30px" }}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "var(--Text, #1A1D1F)",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "150%",
          }}
        >
          {modalHeadingText}
        </DialogTitle>
        <DialogContent
          sx={{
            textAlign: "center",
            color: "#141416",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%",
          }}
        >
          {confirmationText}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center", gap: "12px" }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              color: "#207FB9",
              border: "1px solid #207FB9",
              display: "inline-flex",
              padding: "10px 20px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "4px",

            }}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            sx={{
              color: "#FFF",
              border: `1px solid ${confirmButtonColor}`,
              display: "inline-flex",
              padding: "10px 20px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "4px",
              backgroundColor: `${confirmButtonColor} !important`,
            }}
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </ArgonBox>
    </Dialog>
  );
};

ResetConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  confirmButtonColor: PropTypes.string,
  modalHeadingText: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
};

export default ResetConfirmModal;
